import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="usa-hero" aria-label="Introduction">
      <div className="grid-container">
        <div className="usa-hero__callout">
          <h1 className="usa-hero__heading">
            <span className="usa-hero__heading--alt">4i APIs:</span>
            Manage model operations
          </h1>
          <p>
            Use APIs to manage your CMMI model participation or model
            administration operations
          </p>

          <Link to="/overview" className="usa-button">
            <span>Learn More</span>
          </Link>
        </div>
      </div>
    </section>

    <section className="grid-container usa-section">
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-4">
          <h2 className="font-heading-xl margin-top-0 tablet:margin-bottom-0">
            A secure way to automate, integrate and manage model operations
          </h2>
        </div>
        <div className="tablet:grid-col-8 usa-prose">
          <p>
            Every capability that is available on 4Innovation web application is
            powered by APIs in 4Innovation Platform.
          </p>
          <p>
            Use the same APIs to securely interact with 4Innovation platform to
            automate tasks, integrate with internal systems and manage all
            operations. For example download all CCLF files, data extracts and
            reports automatically for all entities supported.
          </p>
        </div>
      </div>
    </section>

    <section className="usa-graphic-list usa-section usa-section--dark">
      <div className="grid-container">
        <div className="usa-graphic-list__row grid-row grid-gap">
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">
                Provider Vetting API
              </h2>
              <p>
                Instantly verify provider information using Provider Enrollment,
                Chain, and Ownership System (PECOS) data and offer realtime
                feedback to model participants
              </p>
            </div>
          </div>
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">
                More APIs coming soon.
              </h2>
              <p>
                More 4Innovation APIs like Secure Data Hub API, Participant
                Management API, Agreement Management API, etc. will be made
                available soon.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="usa-graphic-list__row grid-row grid-gap">
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">
                Participant Management API
              </h2>
              <p>
                For anything you want to highlight here, understand what your
                users know now, and what activity or impression you want from
                them after they see it.
              </p>
            </div>
          </div>
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">
                Agreement Management API
              </h2>
              <p>
                In addition to your goal, find out your users’ goals. What do
                they want to know or do that supports your mission? Use these
                headings to show those.
              </p>
            </div>
          </div>
        </div>
        <div className="usa-graphic-list__row grid-row grid-gap">
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">User Management API</h2>
              <p>
                For anything you want to highlight here, understand what your
                users know now, and what activity or impression you want from
                them after they see it.
              </p>
            </div>
          </div>
          <div className="usa-media-block tablet:grid-col">
            <img
              className="usa-media-block__img"
              src="/assets/img/circle-124.png"
              alt="Alt text"
            />
            <div className="usa-media-block__body">
              <h2 className="usa-graphic-list__heading">And more</h2>
              <p>
                In addition to your goal, find out your users’ goals. What do
                they want to know or do that supports your mission? Use these
                headings to show those.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>

    <section id="test-section-id" className="usa-section">
      <div className="grid-container">
        <h2 className="font-heading-xl margin-y-0">Getting Started</h2>
        <p className="usa-intro">
          To use any of the 4Innovation APIs, you need to request for API
          Credentials. A self-service page that allows you to create API
          credentials will be made available soon.
        </p>

        <Link to="/overview" className="usa-button usa-button--big">
          <span>Learn More</span>
        </Link>
      </div>
    </section>
  </Layout>
)

export default IndexPage
